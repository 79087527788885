import axios from 'axios';
import { env } from 'env';
import { AccountUpdate, BodyMeasurement, PostWorkoutRequest, Workout } from 'hevy-shared';
import Cookies from 'js-cookie';

export interface APIData<T> {
  data: T;
}

const api = axios.create({
  baseURL: env.apiUrl,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': env.apiKey,
  },
});

let authToken: string | undefined = Cookies.get('auth-token');
const setAuthToken = (newAuthToken?: string) => {
  authToken = newAuthToken;
};

const requestHeadersModifier =
  (withHeaders: any) =>
  (config: any): any => {
    const headers = config.headers || {};
    const modification = withHeaders(headers);

    return modification ? { ...config, headers: { ...(headers || {}), ...modification } } : config;
  };

const authenticationRequestInterceptor = requestHeadersModifier(() => {
  return authToken ? { 'auth-token': authToken } : null;
});

api.interceptors.request.use(authenticationRequestInterceptor);

const updateAccount = (account: AccountUpdate) => api.put('account', { account });

const postWorkout = ({ workout }: PostWorkoutRequest): Promise<APIData<Workout>> =>
  api.post('v2/workout', { workout });

const postBodyMeasurements = (measurements: Omit<BodyMeasurement, 'id' | 'created_at'>) =>
  api.post('body_measurements', { measurements });
const postWorkoutComment = (workoutId: string, comment: string) =>
  api.post('workout_comment', { workoutId, comment });

// Interface to use when interacting on the sample client's behalf
const SampleClientAPI = {
  setAuthToken,
  updateAccount,
  postWorkout,
  postBodyMeasurements,
  postWorkoutComment,
};

export default SampleClientAPI;
